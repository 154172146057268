import { FeatureFlagsConfig } from './entities';

export const sessionRecorderExperimentName = 'session_recorder_redistributor';

export const categorizedExpertCatalogueExperimentName = 'regcat_4_categorized_catalogue';

export const phoneNumberOptInFeature = 'phone_number_opt_in';

export const simpleSimpleChatOnCatalogueExperiment = 'simple_simple_chat_on_catalogue';

export const fbAutologinSimpleRegExperimentName = 'fb_autologin_simple_reg';

export const SimpleRegExplanationScreens3ExperimentName = 'simple_reg_explanation_screens_3';

export const simpleRegContextQuestionsExperimentName = 'context_questions_simple_reg_4';

export const simpleRegQuestionsOnLoaderExperimentName = 'questions_on_loader_simple_reg';

export const SimpleRegChatOnFunnel2ExperimentName = 'simple_reg_chat_on_funnel_2';

export enum SimpleRegUserName2ExperimentGroups {
  CONTROL = 'user_name_as_is',
  WITHOUT_TEXT = 'user_name_push_without_text',
  WITH_TEXT = 'user_name_push_with_text',
}

export enum SessionRecorderExperimentGroups {
  RECORD_ON = 'record_on',
  RECORD_OFF = 'record_off',
}

export enum CategorizedExpertCatalogueExperimentGroups {
  CONTROL = 'reg_cat_as-is',
  WITH_CATEGORIZED_CATALOGUE = 'reg_cat_categorized_catalogue',
}

export enum FbAutologinSimpleRegExperimentGroups {
  CONTROL = 'as-is',
  FB_AUTOLOGIN = 'fb_autologin',
}

export enum SimpleSimpleChatOnCatalogueExperimentGroups {
  CONTROL = 'simple_simple_as-is',
  TEST_2 = 'simple_simple_chat_with_redirect',
}

export enum SimpleRegExplanationScreens3ExperimentGroups {
  CONTROL = 'simple_reg_as-is',
  EXTRA_SCREENS = 'simple_reg_extra_screens',
}

export enum SimpleRegUserNameOnFunnelExperimentGroups {
  CONTROL = 'as-is',
  MANDATORY_NAME = 'user_name_simple_reg_unskip',
  OPTIONAL_NAME = 'user_name_simple_reg_skip',
}

export enum SimpleRegEmailPages3ExperimentGroups {
  CONTROL = 'simple_reg_email_page_as-is',
  PAGE_COPY_CHANGES = 'simple_reg_email_page_copy_change',
  PAGE_EXPERTS_CATALOGUE = 'simple_reg_email_page_experts_catalogue',
  PAGE_WITH_BACK = 'simple_reg_email_page_win-back',
}

export enum SimpleRegLandingPageImprovementExperimentGroups {
  CONTROL = 'simple_reg_as-is',
  SHORT_HEADER = 'simple_reg_short_header',
  LONG_HEADER = 'simple_reg_long_header',
}

export enum SimpleRegContextQuestionsExperimentGroups {
  CONTROL = 'as-is',
  PERSONALIZE = 'context_questions_old_third_section',
  EXTENDED_FIRST_SECTION = 'context_questions_new_third_section',
}

export enum SimpleRegQuestionsOnLoaderExperimentGroups {
  CONTROL = 'as-is',
  THREE_EXTRA_QUESTIONS = 'three_extra_questions',
  THREE_EXTRA_QUESTIONS_CHANGE_COPY = 'three_extra_questions_change_copy',
}

export enum SimpleRegChatOnFunnel2ExperimentGroups {
  CONTROL = 'simple_reg_as-is',
  EXPERTS = 'simple_reg_experts',
  ONLY_MESSAGE = 'simple_reg_only_message',
}

export const featureFlagsConfig: FeatureFlagsConfig = {
  features: {
    [sessionRecorderExperimentName]: {
      defaultValue: SessionRecorderExperimentGroups.RECORD_OFF,
      rules: [
        {
          variations: [
            SessionRecorderExperimentGroups.RECORD_OFF,
            SessionRecorderExperimentGroups.RECORD_ON,
          ],
          weights: [0.8, 0.2],
        },
      ],
      shouldNotTrack: true,
    },
    [categorizedExpertCatalogueExperimentName]: {
      defaultValue: CategorizedExpertCatalogueExperimentGroups.CONTROL,
      rules: [
        {
          seed: 'as-is_vs_categorized_catalogue',
          hashVersion: 2,
          variations: [
            CategorizedExpertCatalogueExperimentGroups.CONTROL,
            CategorizedExpertCatalogueExperimentGroups.WITH_CATEGORIZED_CATALOGUE,
          ],
          weights: [0.5, 0.5],
        },
      ],
    },
    [simpleSimpleChatOnCatalogueExperiment]: {
      defaultValue: SimpleSimpleChatOnCatalogueExperimentGroups.CONTROL,
    },
    [fbAutologinSimpleRegExperimentName]: {
      defaultValue: FbAutologinSimpleRegExperimentGroups.CONTROL,
    },
    [SimpleRegExplanationScreens3ExperimentName]: {
      defaultValue: SimpleRegExplanationScreens3ExperimentGroups.CONTROL,
    },
    [phoneNumberOptInFeature]: {
      defaultValue: null,
    },
    [simpleRegContextQuestionsExperimentName]: {
      defaultValue: SimpleRegContextQuestionsExperimentGroups.CONTROL,
    },
    [simpleRegQuestionsOnLoaderExperimentName]: {
      defaultValue: SimpleRegQuestionsOnLoaderExperimentGroups.CONTROL,
    },
    [SimpleRegChatOnFunnel2ExperimentName]: {
      defaultValue: SimpleRegChatOnFunnel2ExperimentGroups.CONTROL,
    },
  },
};
