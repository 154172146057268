/* eslint-disable max-len */
import { SECOND } from 'src/core/common/constants';
import { QueryCacheProviderOptions } from 'src/core/queryCache/entities';
import { convertCredits } from 'src/core/common/entities';
import { HOUR_IN_SECONDS } from 'src/core/common/utils/date';
import { CategorizedExpertsSection, ExpertCategory } from './core/experts/entities';

export type Config = {
  env: AppEnvironment;
  appName: string;
  mainAppUrl: string;
  domainRoot: string;
  appOrigin: string;
  apiUrl: string;
  apiPrefix: string;
  amplitudeApiKey: string;
  amplitudeProxyUrl: string;
  googleAnalyticsId: string;
  googleClientId: string;
  googleMapApiKey: string;
  googleMapEmbedBaseUrl: string;
  smartlook: {
    apiKey: string;
    setupDelay: number;
  };
  geoCodeUrl: string;
  geoCodeApiKey: string;
  testingTokenCookieKey: string;
  defaultChatPrice: number;
  defaultChatPriceInCredits: number;
  defaultOfflineQuestionPrice: number;
  defaultOfflineQuestionPriceInCredits: number;
  defaultWelcomeCreditsAmount: number;
  defaultWeeklyCreditsAmount: number;
  initialExpertsAmount: number;
  expertCardVisibilityThreshold: number;
  isProduction: boolean;
  isStage: boolean;
  sentryDsn: string;
  contentManager: {
    url: string;
    apiPrefix: string;
    requestConfig: {
      headers: {
        Authorization: `bearer ${string}`;
      };
    };
    clientRequestConfig: {
      headers: {
        Authorization: `bearer ${string}`;
      };
    };
    maxPaginationAmount: number;
    defaultGeneratedPagesAmount: number;
  };
  technicalLinks: {
    nebula: {
      email: string;
      support: string;
      supportEmail: string;
      prEmail: string;
      careerEmail: string;
      googlePlay: string;
      appStore: string;
      instagram: string;
      tiktok: string;
      facebook: string;
      twitter: string;
      threads: string;
    };
    google: {
      cancelSubscription: string;
      refund: string;
      privacy: string;
      optOutAds: string;
      optOutGA: string;
      partnerSites: string;
    };
    firebase: {
      privacy: string;
      processingTerms: string;
    };
    apple: {
      cancelSubscription: string;
      refund: string;
      privacy: string;
    };
    appNebula: {
      main: string;
      moneyBackPolicy: string;
    };
    appsFlyer: {
      privacy: string;
      outOut: string;
    };
    facebook: {
      privacy: string;
      customAudience: string;
      influenceAds: string;
      adjustAds: string;
    };
    fabric: {
      dataProcessingAndSecurity: string;
      privacy: string;
    };
    oneSignal: {
      privacy: string;
    };
    amplitude: {
      privacy: string;
    };
    legal: {
      americanArbitrationAssociation: string;
      networkAdvertisingInitiative: {
        main: string;
        optOut: string;
      };
      digitalAdvertisingAlliance: {
        main: string;
        canada: string;
        EU: string;
        adChoices: string;
      };
      europeanCommission: {
        contractualClauses: string;
        adequacyDecisions: string;
      };
      californiaPersonalInformation: string;
      virginiaAttorneyOffice: string;
    };
    becomePsychic: {
      applicationForm: string;
    };
    twillio: {
      privacy: string;
    };
    microsoft: {
      privacy: string;
    };
  };
  growthbook: {
    apiHost: string;
    clientKey: string;
  };
  defaultFreeMinutes: number;
  experimentalFreeMinutes: number;
  freeTrialCreditsCurrencyEquivalent: number;
  googleAnalytics: {
    apiUrl: string;
    measurementId: string;
    apiSecret: string;
    clientIdKey: string;
    sessionIdKey: string;
    engagementTime: string;
  };
  pageRevalidationIntervalsSeconds: {
    default: number | boolean;
  };
  defaultQueryCacheProviderOptions: QueryCacheProviderOptions;
  SVGSprite: {
    inputPath: string;
    outputPath: string;
    typesOutputPath: string;
    outputName: `${string}.svg`;
  };
  buildCache: {
    path: string;
  };
  oneTrust: {
    key: string;
  };
  iceBreakingTips: string[];
  expertMessages: string[];
  remoteAssetsStorageUrl: string;
  facebookAuthAppId: string;
  grafanaLogger: {
    url: string;
  };
  clarityId: string;
  observability: {
    defaultTracerName: string;
    tracesUrl: string;
    httpInstrumentationIgnoreUrls: Array<string>;
    httpInstrumentationSpanRate: number;
  };
  matchExpertPercentages: Array<number>;
  expertsSections: Record<string, CategorizedExpertsSection>;
  supportBot: {
    tags: Array<string>;
    apiKey: string;
  };
};

export enum AppEnvironment {
  DEV = 'dev',
  STAGE = 'stage',
  PRODUCTION = 'production',
}

const baseConfig: Config = {
  env: AppEnvironment.DEV,
  appName: 'asknebula-quiz',
  mainAppUrl: process.env.NEXT_PUBLIC_MAIN_APP_URL || 'https://stage-asknebula.asknebula.com/app',
  domainRoot: 'localhost',
  appOrigin: process.env.NEXT_PUBLIC_APP_ORIGIN || 'https://asknebula.com',
  smartlook: {
    apiKey: process.env.NEXT_PUBLIC_SMARTLOOK_KEY || '',
    setupDelay: SECOND,
  },
  amplitudeApiKey: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY || '',
  amplitudeProxyUrl: process.env.NEXT_PUBLIC_AMPLITUDE_PROXY_URL || '',
  googleClientId: '851007237097-p64g641vb4g4jq341dib2q40ck3osli4.apps.googleusercontent.com',
  apiUrl: process.env.NEXT_PUBLIC_TEST_URL || '',
  apiPrefix: process.env.NEXT_PUBLIC_TEST_PREFIX || '',
  googleAnalyticsId: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID || '',
  googleMapApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY || '',
  googleMapEmbedBaseUrl: 'https://www.google.com/maps/embed/v1/',
  geoCodeUrl: process.env.NEXT_PUBLIC_GEOCODE_URL || '',
  geoCodeApiKey: process.env.NEXT_PUBLIC_GEOCODE_API_KEY || '',
  testingTokenCookieKey: 'tester_token',
  defaultChatPrice: 3,
  defaultChatPriceInCredits: convertCredits(3),
  defaultOfflineQuestionPrice: 8,
  defaultOfflineQuestionPriceInCredits: convertCredits(8),
  defaultWelcomeCreditsAmount: 150,
  defaultWeeklyCreditsAmount: 150,
  initialExpertsAmount: 28,
  expertCardVisibilityThreshold: 0.6,
  isProduction: process.env.NEXT_PUBLIC_APP_ENV === 'production',
  isStage: process.env.NEXT_PUBLIC_APP_ENV === 'stage',
  sentryDsn: process.env.NEXT_PUBLIC_SENTRY_DSN ?? '',
  contentManager: {
    url: process.env.NEXT_PUBLIC_CONTENT_MANAGER_URL || '',
    apiPrefix: process.env.NEXT_PUBLIC_CONTENT_MANAGER_PREFIX || '',
    requestConfig: {
      headers: {
        Authorization: `bearer ${process.env.NEXT_PUBLIC_CONTENT_MANAGER_API_TOKEN || ''}`,
      },
    },
    clientRequestConfig: {
      headers: {
        Authorization: `bearer ${process.env.NEXT_PUBLIC_CONTENT_MANAGER_CLIENT_TOKEN || ''}`,
      },
    },
    maxPaginationAmount: 250,
    defaultGeneratedPagesAmount: 15,
  },
  technicalLinks: {
    nebula: {
      email: 'nebula@gen.tech',
      support: 'https://help.answers-365.com/',
      supportEmail: 'support.nebula@gen.tech',
      prEmail: 'pr@nebulahoroscope.com',
      careerEmail: 'astrologers@nebulahoroscope.com',
      googlePlay: 'https://play.google.com/store/apps/details?id=genesis.nebula&hl=en&gl=US',
      appStore: 'https://apps.apple.com/us/app/nebula-horoscope-astrology/id1459969523',
      instagram: 'https://www.instagram.com/nebulahoroscope/',
      tiktok: 'https://www.tiktok.com/@nebulahoroscope/',
      facebook: 'https://www.facebook.com/profile.php?id=100063971358465',
      twitter: 'https://twitter.com/nebulahoroscope/',
      threads: 'https://www.threads.net/@nebulahoroscope',
    },
    google: {
      cancelSubscription:
        'https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&hl=en',
      refund: 'https://support.google.com/googleplay/answer/2479637',
      privacy: 'https://policies.google.com/privacy',
      partnerSites: 'https://policies.google.com/technologies/partner-sites',
      optOutAds: 'https://adssettings.google.com/authenticated',
      optOutGA: 'https://tools.google.com/dlpage/gaoptout/',
    },
    apple: {
      cancelSubscription: 'https://support.apple.com/en-us/HT202039',
      refund: 'https://support.apple.com/en-us/HT204084',
      privacy: 'https://www.apple.com/privacy',
    },
    appNebula: {
      main: 'https://appnebula.co/',
      moneyBackPolicy: 'https://appnebula.co/tech/money-back-policy',
    },
    firebase: {
      privacy: 'https://firebase.google.com/support/privacy',
      processingTerms: 'https://firebase.google.com/terms/data-processing-terms',
    },
    appsFlyer: {
      privacy: 'https://www.appsflyer.com/privacy-policy',
      outOut: 'https://www.appsflyer.com/optout',
    },
    facebook: {
      privacy: 'https://www.facebook.com/about/privacy',
      customAudience: 'https://www.facebook.com/business/help/1415256572060999',
      influenceAds: 'https://www.facebook.com/help/146952742043748?helpref=related',
      adjustAds: 'https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen',
    },
    fabric: {
      dataProcessingAndSecurity: 'https://fabric.io/dpst.pdf',
      privacy: 'https://docs.fabric.io/android/fabric/data-privacy.html',
    },
    oneSignal: {
      privacy: 'https://onesignal.com/privacy_policy',
    },
    amplitude: {
      privacy: 'https://amplitude.com/privacy',
    },
    legal: {
      americanArbitrationAssociation: 'https://www.adr.org/',
      networkAdvertisingInitiative: {
        main: 'https://optout.networkadvertising.org',
        optOut: 'https://thenai.org/opt-out/mobile-opt-out/',
      },
      digitalAdvertisingAlliance: {
        main: 'https://optout.aboutads.info',
        canada: 'https://youradchoices.ca/choices',
        EU: 'https://www.youronlinechoices.com',
        adChoices: 'https://www.aboutads.info/appchoices',
      },
      europeanCommission: {
        contractualClauses:
          'https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en',
        adequacyDecisions:
          'https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en',
      },
      californiaPersonalInformation:
        'https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=CIV&amp;sectionNum=1798.81.5',
      virginiaAttorneyOffice: 'https://virginia.gov/agencies/office-of-the-attorney-general/#vagov',
    },
    becomePsychic: {
      applicationForm: 'https://forms.gle/rxzuapsRiFvQU6B67',
    },
    twillio: {
      privacy: 'https://www.twilio.com/en-us/legal/privacy',
    },
    microsoft: {
      privacy: 'https://privacy.microsoft.com/en-us/privacystatement',
    },
  },
  growthbook: {
    apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_API_URL || '',
    clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY || '',
  },
  defaultFreeMinutes: 3,
  experimentalFreeMinutes: 5,
  freeTrialCreditsCurrencyEquivalent: 14,
  googleAnalytics: {
    apiUrl: 'https://www.google-analytics.com',
    measurementId: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID || '',
    apiSecret: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_API_SECRET || '',
    clientIdKey: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_CLIENT_ID_KEY || '',
    sessionIdKey: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_SESSION_ID_KEY || '',
    engagementTime: '100',
  },
  pageRevalidationIntervalsSeconds: {
    default: HOUR_IN_SECONDS * 6,
  },
  defaultQueryCacheProviderOptions: {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    errorRetryCount: 5,
  },
  SVGSprite: {
    inputPath: 'src/core/svg-sprites/assets/icons',
    outputPath: 'public/images/sprites',
    typesOutputPath: 'src/core/svg-sprites/entities',
    outputName: 'sprite.svg',
  },
  buildCache: {
    path: '/.build-request-cache',
  },
  oneTrust: {
    key: process.env.NEXT_PUBLIC_ONE_TRUST_KEY ?? '',
  },
  iceBreakingTips: [
    'Will my ex and I get back together?',
    'When will I start a new relationship?',
    'Can you see any unresolved feelings between my ex and me?',
    'How is my ex feeling about me?',
    'Is there anything unresolved with my ex?',
    'Does my ex think about me?',
    'Did my ex cheat on me?',
  ],
  expertMessages: [
    "Hello my dear, I think it's time to give you answers to the questions that concern you. What would you like to" +
      ' find out?',
    "Nice to meet you! I'm here to help you to find answers on things that bothered you. You can ask me everything" +
      ' you want',
    'Hello, dear! I’m here to help you with your questions to the Universe. What is bothered you?',
  ],
  remoteAssetsStorageUrl: 'https://media.asknebula.com',
  grafanaLogger: {
    url: process.env.NEXT_PUBLIC_GRAFANA_LOGGER_URL ?? '',
  },
  facebookAuthAppId: '996775074296288',
  observability: {
    defaultTracerName: 'default_tracer',
    tracesUrl: process.env.NEXT_PUBLIC_TRACES_URL ?? '',
    httpInstrumentationIgnoreUrls: [process.env.NEXT_PUBLIC_GRAFANA_LOGGER_URL].filter(
      Boolean,
    ) as Array<string>,
    httpInstrumentationSpanRate: 0.2,
  },
  clarityId: process.env.NEXT_PUBLIC_CLARITY_ID || '',
  matchExpertPercentages: [100, 99, 97, 89, 88, 86],
  expertsSections: {
    [ExpertCategory.LOVE_READINGS]: {
      isLight: true,
      title: 'BEST IN LOVE & RELATIONSHIP',
      description:
        'advisors with the highest accuracy in providing insights about love and relationship matters',
    },
    [ExpertCategory.DESTINY_AND_LIFE_PATH]: {
      title: 'Best in Destiny & Life path',
      description: 'advisors exceptionally accurate in illuminating your destiny and life path',
    },
    [ExpertCategory.CAREER_AND_WORK]: {
      title: 'Best in Career & work ',
      description:
        'advisors known for their precision in navigating career and work-related questions',
    },
    [ExpertCategory.FUTURE_TELLING]: {
      title: 'Best in Future telling',
      description: 'advisors revered for their accuracy in predicting future events and outcomes.',
    },
    [ExpertCategory.FAMILY_AND_FRIENDS]: {
      title: 'Best in Family & Friends topic',
      description:
        'advisors acclaimed for their accuracy in family and friendship-related inquiries',
    },
    [ExpertCategory.SPIRIT_GUIDANCE]: {
      title: 'Best in Spirit guidance',
      description:
        'spiritual guides recognized for their exactitude in providing spiritual advice and direction',
    },
    [ExpertCategory.MOST_ACCURATE]: {
      title: 'TOP 3 PSYCHICS FOR YOU',
      description:
        'best 3 psychics with the highest matching scores according to your quiz answers',
    },
    [ExpertCategory.ALL]: {
      title: 'all advisors',
    },
  },
  supportBot: {
    apiKey: 'b57b661b-8a7f-40e1-9e62-0f2630788e9f',
    tags: ['Ask_Nebula'],
  },
};

const stageConfig: Partial<Config> = {
  env: AppEnvironment.STAGE,
  domainRoot: '.asknebula.com',
  clarityId: process.env.NEXT_PUBLIC_CLARITY_ID || '',
};

const productionConfig: Partial<Config> = {
  env: AppEnvironment.PRODUCTION,
  domainRoot: '.asknebula.com',
  googleClientId: '851007237097-v2ham72kc3el6c37sump46n72ti5ccut.apps.googleusercontent.com',
  clarityId: process.env.NEXT_PUBLIC_CLARITY_ID || '',
};

const getConfig = (): Config => {
  const isProductionBuild = process.env.NEXT_PUBLIC_ENVIRONMENT === AppEnvironment.PRODUCTION;
  const isStageBuild = process.env.NEXT_PUBLIC_ENVIRONMENT === AppEnvironment.STAGE;

  if (isProductionBuild) {
    return { ...baseConfig, ...productionConfig };
  }

  if (isStageBuild) {
    return { ...baseConfig, ...stageConfig };
  }

  return baseConfig;
};

export const config: Config = getConfig();
